$mdc-theme-primary: #1062c8;
$mdc-theme-secondary: #00bfa5;
$mdc-theme-on-primary: #ffffff;
$mdc-theme-on-secondary: #ffffff;

@import '@material/react-text-field/index.scss';
@import '@material/react-button/index.scss';
@import "@material/react-dialog/index.scss";
@import "@material/react-chips/index.scss";
@import '@material/react-linear-progress/index.scss';
@import '@material/react-card/index.scss';
@import '@material/react-text-field/index.scss';
@import "@material/react-dialog/index.scss";
@import '@material/react-linear-progress/index.scss';
@import '@material/react-top-app-bar/index.scss';
@import '@material/react-material-icon/index.scss';
@import "@material/react-drawer/index.scss";
@import '@material/react-list/index.scss';
@import '@material/react-icon-button/index.scss';
@import '@material/react-layout-grid/index.scss';
@import '@material/react-fab/index.scss';