.drawer-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.drawer-app-content {
  flex: auto;
  overflow: auto;
}

.mdc-card {
    margin: 4px;
}

.otpcode {
    font-size: 2em;
    font-weight: bold;
}

.seedInfoItem {
    padding: 12px;
}

#addbtn{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}

#otpCardsGrid {
    padding-bottom: 5rem;
}

.decryptPrompt{
    text-align: center;
    margin: 32px;
}

.listMargin{
    max-width: 1000px;
    margin: 0 auto 0 auto;
}